import React, { ButtonHTMLAttributes } from "react";

import { ArrowRightIcon } from "@heroicons/react/20/solid";
import Link, { LinkProps } from "next/link";
import { twJoin, twMerge } from "tailwind-merge";

export type ButtonSize = "large" | "medium" | "small";

export const LegacyButtonSize: Record<string, ButtonSize> = {
  SECONDARY: "medium",
  PRIMARY: "large",
  TERTIARY: "small",
};

type ButtonIconPosition = "right" | "left";

type ButtonVariant = "solid" | "outlined";

export type ButtonColorScheme = "accent" | "dark" | "light";

interface ButtonBaseProps {
  label: string;
  size?: ButtonSize;
  icon?: React.FC<React.ComponentPropsWithRef<"svg">>;
  iconPosition?: ButtonIconPosition;
  variant?: ButtonVariant;
  colorScheme?: ButtonColorScheme;
  loading?: boolean;
  className?: string;
  id?: string;
  onClick?: () => void;
}

interface ButtonProps extends ButtonBaseProps {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
}

interface ButtonLinkProps extends ButtonBaseProps {
  href: LinkProps["href"];
  target?: string;
  rel?: string;
}

const Button = ({
  label,
  icon,
  iconPosition,
  variant,
  colorScheme,
  loading,
  size,
  className,
  ...buttonElemProps
}: ButtonProps) => {
  const { wrapperClassName, children } = buttonSharedLogic({
    label,
    icon,
    iconPosition,
    variant,
    colorScheme,
    size,
    loading,
    className,
  });

  return (
    <button className={wrapperClassName} {...buttonElemProps}>
      {children}
    </button>
  );
};

export default Button;

export const ButtonLink = ({
  label,
  icon,
  iconPosition,
  variant,
  colorScheme,
  loading,
  size,
  className,
  ...linkElemProps
}: ButtonLinkProps) => {
  const { wrapperClassName, children } = buttonSharedLogic({
    label,
    icon,
    iconPosition,
    variant,
    colorScheme,
    size,
    loading,
    className,
  });

  return (
    <Link className={twJoin("override-hover no-underline", wrapperClassName)} {...linkElemProps}>
      {children}
    </Link>
  );
};

const buttonSharedLogic = ({
  label,
  icon,
  iconPosition = "right",
  variant = "outlined",
  colorScheme = "light",
  size = "large",
  loading,
  className,
}: ButtonBaseProps) => {
  const Icon = icon ?? ArrowRightIcon;

  const wrapperClassName = twMerge(
    "group relative flex w-fit items-center border-2 font-sans font-semibold leading-none uppercase transition duration-200 md:border-[3px] active:scale-95 disabled:pointer-events-none disabled:opacity-40",

    size === "small" && "body-xs h-[3.2em] px-[1em]",
    size === "medium" && "body-sm h-[3.6em] px-[1.125em]",
    size === "large" && "body-md h-[4em] px-[1.5em]",

    // Set button colors in the CSS variables main-color and contrast-color
    colorScheme === "light" &&
      "[--btn-main-color:theme(colors.white)] [--btn-contrast-color:theme(colors.black)]",
    colorScheme === "dark" &&
      "[--btn-main-color:theme(colors.black)] [--btn-contrast-color:theme(colors.white)]",
    colorScheme === "accent" &&
      "[--btn-main-color:theme(colors.brand.500)] [--btn-contrast-color:theme(colors.white)]",

    // Border uses always main-color
    "border-[var(--btn-main-color)]",
    variant === "solid" && "bg-[var(--btn-main-color)]",
    variant === "outlined" && "bg-transparent hover:bg-[var(--btn-main-color)]",

    loading && "pointer-events-none",
    className
  );

  const children = (
    <>
      <span
        className={twJoin(
          // Setup some useful CSS variables (using em to scale with font size)
          "[--btn-icon-size:1.33em]",
          // Gap between icon and label
          "[--btn-gap:0.4em]",
          // Offset needed to center the label in the button when the icon is not visible
          "[--btn-label-offset:calc((var(--btn-icon-size)+var(--btn-gap))/2)]",
          "flex w-full items-center justify-center gap-[var(--btn-gap)]",
          loading && "opacity-0",
          iconPosition === "left" && "flex-row-reverse"
        )}
      >
        <span
          className={twJoin(
            "whitespace-nowrap transition duration-200 group-hover:translate-x-0",
            iconPosition === "left" && "-translate-x-[var(--btn-label-offset)]",
            iconPosition === "right" && "translate-x-[var(--btn-label-offset)]",
            variant === "solid" && "text-[var(--btn-contrast-color)]",
            variant === "outlined" &&
              "text-[var(--btn-main-color)] group-hover:text-[var(--btn-contrast-color)]"
          )}
        >
          {label}
        </span>
        <Icon
          aria-hidden
          className={twJoin(
            "shrink-0 size-[var(--btn-icon-size)] opacity-0 transition duration-200 group-hover:opacity-100 group-hover:translate-x-0",
            // Color of the icon is fixed because transition of opacity and
            // color at the same time looks very bad
            "text-[var(--btn-contrast-color)]",
            iconPosition === "left" && "translate-x-[var(--btn-gap)]",
            iconPosition === "right" && "-translate-x-[var(--btn-gap)]"
          )}
        />
      </span>

      {loading && (
        <span
          aria-hidden
          className={twJoin(
            "absolute size-[.6em] left-1/2 -ml-[.3em] animate-pure-ping",
            variant === "solid" && "bg-[var(--btn-contrast-color)]",
            variant === "outlined" && "bg-[var(--btn-main-color)]"
          )}
        />
      )}
    </>
  );

  return { wrapperClassName, children };
};
