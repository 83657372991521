import React, { useEffect, useRef } from "react";

import { gsap } from "gsap";
import { CustomEase } from "gsap/dist/CustomEase";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { twJoin } from "tailwind-merge";

import CompanyLogo from "components/CompanyLogo";
import TransitionLink from "components/TransitionLink";

import IntroAre from "assets/brand/home/intro-are.svg";
import IntroWe from "assets/brand/home/intro-we.svg";
import Wordmark from "assets/brand/logos/wordmark.svg";

import { COMPANIES } from "data/companies";
import { PROJECTS } from "data/home";

// import './HomeProjects.scss';

const HomeProjects = ({ speed }: { speed: number }) => {
  const sqHeroRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Default fade transition between slides
      // idx (num): the index of the slide you are transitioning to / from
      // slugCur (string): the slug name of the slide you are currently on (ex: "wirecutter")
      // slugNext (string): the slug name of the slide you are transitioning to
      // forward (boolean): is this transition moving forward (down the page) or backwards (up the page)?
      function defaultTransition(idx: number, forward: boolean) {
        // Determining slide slugs based on idx + foward
        const slugCur = PROJECTS[forward ? idx - 1 : idx].slug;
        const slugNext = PROJECTS[forward ? idx : idx - 1].slug;

        const idxCur = forward ? idx - 1 : idx;
        const idxNext = forward ? idx : idx - 1;

        // Background
        gsap.to(`#bg-${idx}`, { opacity: forward ? 1 : 0, duration: speed * 1.5, ease: "none" });

        // Squares (intro square, idx 1, has a different transition behavior
        if (idx === 1) {
          gsap.to(`#sq-${idx - 1}`, {
            borderWidth: forward ? "0px" : "7px",
            opacity: forward ? 0 : 1,
            duration: speed,
            delay: forward ? 0 : speed,
            ease: "none",
          });
        }
        // Other square
        else {
          gsap.to(`#sq-${idx - 1}`, {
            opacity: forward ? 0 : 1,
            duration: speed,
            ease: "none",
          });
        }
        // prettier-ignore
        gsap.to(`#sq-${idx}`, { opacity: forward ? 1 : 0, duration: speed, ease: "none" });
        // prettier-ignore
        gsap.set(`#sq-${idx}`, { visibility: forward ? "visible" : "hidden", delay: forward ? 0 : speed });
        // prettier-ignore
        gsap.set(`#sq-${idx - 1}`, { visibility: !forward ? "visible" : "hidden", delay: !forward ? 0 : speed });

        // Text
        const refCur = idx === 4 ? ".text__warby" : `#text-${idx - 1}`;
        const refNext = idx === 3 ? ".text__warby" : `#text-${idx}`;
        // Move current away
        gsap.to(forward ? refCur : refNext, {
          y: forward ? "-=32" : "+=32",
          duration: speed * 2,
          ease: "expo.out",
        });
        gsap.to(forward ? refCur : refNext, {
          scale: 1.03,
          opacity: 0,
          duration: speed * 2,
          ease: "circ.out",
        });
        // Set it to be hidden
        gsap.set(forward ? refCur : refNext, { visibility: "hidden", delay: speed * 2 });
        // Set up the next
        gsap.set(forward ? refNext : refCur, {
          y: forward ? 32 : -32,
          opacity: 0,
          scale: 1.03,
          visibility: "visible",
        });
        // Move next in
        gsap.to(forward ? refNext : refCur, {
          y: forward ? "-=30" : "+=30",
          duration: speed * 6,
          delay: speed,
          ease: "expo.out",
        });
        gsap.to(forward ? refNext : refCur, {
          scale: 1,
          opacity: 1,
          duration: speed * 3,
          delay: speed,
          ease: "expo.out",
        });

        // Turns off warby blur when transitioning in
        if ((idx === 3 && forward) || (idx === 4 && !forward)) {
          // entering warby
          gsap.set(".text__warby.before", { clearProps: "filter", delay: speed * 6 });
        } else if ((idx === 4 && forward) || (idx === 3 && !forward)) {
          // leaving warby
          gsap.set(".text__warby.before", { filter: "none", delay: speed * 2 });
        }

        // Info
        // Why do we use refs some times and slugX other times?:
        // Ideally we'd use refs 100% of the time, but if we're working with child elements (ex: li)
        // we need to use a CSS selector. AFAIK GSAP selectors cannot do a combo of a ref + a child selector.
        // So we need to use the id of the parent, which is #info- + the slide slug (ex: #info-wirecutter)
        // TODO: You could potentially grab the child of the ref and grab the first classname? but it feels less airtight
        // Will happen every time:
        gsap.set(`#info-${idxCur}`, { visibility: "hidden", delay: forward ? speed : speed / 2 });
        gsap.set(`#info-${idxNext}`, { visibility: "visible" });
        // Hero related
        if (slugCur === "hero" || slugNext === "hero") {
          gsap.to("#info-0", {
            opacity: forward ? 0 : 1,
            duration: speed,
            delay: forward ? 0 : speed,
            overwrite: true,
          });
          gsap.set("#info-0", {
            opacity: forward ? 0 : 1,
            delay: forward ? speed : 0,
            overwrite: true,
          });
        }
        if (slugCur !== "hero") {
          // Fading out the current logos
          gsap.to(`#info-${idxCur} .v-home-projects__logos`, {
            opacity: 0,
            duration: speed,
            ease: "none",
          });
          // Fading out and moving the current tags
          gsap.to(`#info-${idxCur} h6`, {
            opacity: 0,
            y: forward ? "-=5" : "+=5",
            duration: speed / 2,
            stagger: 0.1,
            ease: "none",
          });
        }
        if (slugNext !== "hero") {
          // Setting the next logos
          gsap.set(`#info-${idxNext} .v-home-projects__logos`, { opacity: 0 });
          // Setting the next tags
          gsap.set(`#info-${idxNext} h6`, { y: forward ? 5 : -5 });
          // Fading in the next logos
          gsap.to(`#info-${idxNext} .v-home-projects__logos`, {
            opacity: 1,
            duration: speed,
            delay: speed,
            ease: "none",
          });
          // Fading in and moving the next tags
          gsap.to(`#info-${idxNext} h6`, {
            opacity: 1,
            y: forward ? "-=5" : "+=5",
            duration: speed / 2,
            stagger: 0.1,
            delay: speed / 2,
            ease: "none",
          });
        }

        // Scrollbar
        if (idx === 1) {
          gsap.to("#scroller", { opacity: forward ? 1 : 0, duration: speed });
        }
        gsap.to("#track", {
          backgroundColor: PROJECTS[idxNext].progressBG,
          opacity: slugNext === "apple" ? 0.5 : 1,
          duration: speed,
          ease: "none",
        });
      }

      // ----- ANIMATION DECLARATIONS ----- //

      // 0: Intro
      // prettier-ignore
      gsap.timeline({ paused: false })
      // We are Fueled
      // Set up
      .set(".v-home-projects__intro-chunk", {opacity: 0}, 0)
      .set("#intro-block", {opacity: 1}, 0)
      // Slow zoom out
      .to(".v-home-projects__intro",{ scale: (((document.getElementById("sq-0")?.offsetWidth || -1)) / window.innerWidth), duration: 1.750, ease: CustomEase.create("custom", "M0,0 C1,0 0.588,1 1,1")}, 0)
      // We
      .to("#intro-we", {opacity: 1, duration: .2 }, 0)
      .to("#intro-we", {opacity: 0, duration: .47 }, 0.2)
      // Are
      .to("#intro-are", {opacity: 1, duration: .2 }, .52)
      .to("#intro-are", {opacity: 0, duration: .47 }, .72)
      // Fueled
      .to("#intro-fueled", {opacity: 1, duration: .39 }, 1.7)
      // Block
      .to("#intro-block", {scale: 1, duration: .7, ease: CustomEase.create("custom", "M0,0,C1,0,0.59,1,1,1")}, 3)
    // Initial setup
    // .set("#sq-0", { visibility: "visible", opacity: 1, borderWidth: 0, }, 0)
    .set("#text-0", { visibility: "visible", opacity: 0 }, 0)
    .set("#info-0", { visibility: "visible", opacity: 0 }, 0)
    // // Square
    .to("#sq-0", {borderWidth: 5, duration: 0.75, ease: "back.out"}, 3.5)
    // Hide
    .set(".v-home-projects__intro", { display: "none" })
    // // Text
    .to("#text-0", { opacity: 1, duration: 1 }, 4)
    // // Info
    .to("#info-0", { opacity: 1, duration: 1.5 }, 4.3);

      // Generates animation scene for each slide:
      // - Transition between slides
      for (let idx = 1; idx <= PROJECTS.length - 1; idx++) {
        ScrollTrigger.create({
          trigger: `#b-${idx}`,
          invalidateOnRefresh: true,
          // markers: {
          //   startColor: "white",
          //   endColor: "white",
          //   fontSize: "14px",
          //   indent: 0,
          // },
          start: "top center",
          end: "top center",
          // Moving to this one
          onEnter: () => {
            defaultTransition(idx, true);
          },
          // Moving to last one
          onEnterBack: () => {
            // Offload press section
            defaultTransition(idx, false);
          },
        });

        // - Scroll-based animations
        addScrollTimeline(idx);
      }
    });
    return () => ctx.revert();
  }, [speed]);

  // Default movement for text, bg, and scrollbar when on a slide
  // idx (num): the index of the slide you are controlling
  function addScrollTimeline(idx: number) {
    const tl = gsap.timeline({
      immediateRender: false,
      scrollTrigger: {
        trigger: `#b-${idx}`,
        scrub: true,
        invalidateOnRefresh: true,
        // markers: {
        //   startColor: "blue",
        //   endColor: "blue",
        //   fontSize: "14px",
        //   indent: 100,
        // },
        start: "top center",
        end: "bottom center",
      },
    });

    // Text
    tl.fromTo(`#text-${idx} span`, { y: 15 }, { y: 0, ease: "none" }, 0);
    // Why span: this scroll control needs to be on a seperate element to prevent overwritting the transition tween
    // Background
    tl.fromTo(`#bg-${idx}`, { y: 2 }, { y: -2 }, 0);
    // Scrollbar
    tl.fromTo("#bar", { y: "-100%" }, { y: 0 }, 0);

    return tl;
  }

  // prettier-ignore
  function Info({ children, link, slug, id, className }: { children: any; link: boolean; slug: string; id: string; className: string }) {
    // Linked project
    if (link) {
      return (
        <TransitionLink href={`/projects/${slug}`} className={className} id={id}>
          {children}
        </TransitionLink>
      );
    }
    // No link
    else {
      return (
        <div className={className} id={id}>
          {children}
        </div>
      );
    }
  }

  return (
    <section className="v-home-projects">
      {/*
        INVISIBLE SPACERS
        Needed for accurate scroll sizing & used as trigger points for animations
      */}
      {PROJECTS.map((project, idx) => (
        <div
          key={project.slug}
          id={`b-${idx}`}
          className="v-home-projects__block flex items-center justify-center"
        />
      ))}

      {/*
        PPROJECT SLIDES
        Every slide (including hero)
      */}
      {PROJECTS.map((project, idx) => (
        <div
          id={`s-${idx}`}
          key={project.slug}
          className={twJoin("v-home-projects__slide", project.slug)}
        >
          {/* BACKGROUND */}
          <div
            id={`bg-${idx}`}
            className="v-home-projects__background"
            // Assigning brand colors from COMPANIES for wirecutter and warby
            style={{
              backgroundColor:
                (project.slug === "wirecutter" && COMPANIES["wirecutter"].color?.accent) ||
                (project.slug === "warby-parker" && COMPANIES["warby-parker"].color?.primary) ||
                undefined,
            }}
          />
          {/* SQUARE */}
          {idx === 0 ? (
            /* Hero Square */
            <div
              id={`sq-${idx}`}
              ref={sqHeroRef}
              className="v-home-projects__square"
              role="presentation"
            >
              <div className="v-home-projects__intro" role="presentation">
                <IntroWe
                  id="intro-we"
                  className="v-home-projects__intro-chunk v-home-projects__intro-chunk--we"
                />
                <IntroAre
                  id="intro-are"
                  className="v-home-projects__intro-chunk v-home-projects__intro-chunk--are"
                />
                <Wordmark
                  id="intro-fueled"
                  className="v-home-projects__intro-chunk v-home-projects__intro-chunk--logo"
                />
              </div>
              <div
                id="intro-block"
                className="v-home-projects__intro-chunk v-home-projects__intro-chunk--square"
              />
            </div>
          ) : (
            /* All others */
            <div
              id={`sq-${idx}`}
              className="v-home-projects__square"
              style={{
                backgroundImage: `url(${process.env.NEXT_PUBLIC_ASSET_PREFIX || ""}${
                  project.squareSrc
                })`,
              }}
            />
          )}

          {/*
            TEXT HEADING
          */}
          {project.slug === "warby-parker" ? (
            // Warby
            <div className="absolute p-[20px]">
              <h1
                id={`text-${idx}`}
                className="v-home__text text__warby before"
                data-heading={`${project.title}`}
              >
                <span className="block">{project.title}</span>
              </h1>
              <h1
                id={`text-${idx}`}
                className="v-home__text text__warby"
                data-heading={`${project.title}`}
              >
                <span className="block">{project.title}</span>
              </h1>
              <h1
                id={`text-${idx}`}
                className="v-home__text text__warby after"
                data-heading={`${project.title}`}
              >
                <span className="block">{project.title}</span>
              </h1>
            </div>
          ) : (
            // All others
            <h1
              id={`text-${idx}`}
              className="v-home__text max-w-[calc(100%-100px)]"
              data-heading={`${project.title}`}
            >
              <span className="block">{project.title}</span>
            </h1>
          )}
        </div>
      ))}

      {/*
        INFOS
        Infos have to be outside containers so they can be position: fixed
        to nav bar on iOS. For some reason position: fixed does not work as
        intended on iOS unless it's at this Nesting level
      */}
      <div className="v-home-projects__details">
        <div id="info-0" className="v-home-projects__blurb text-light">
          <p className="body-md">
            Fueled is an award-winning technology consultancy that transforms businesses by
            generating ideas, building products, and accelerating growth.
          </p>
        </div>
        {PROJECTS.slice(1).map((project, idx) => (
          <Info
            key={project.slug}
            link={project.link}
            slug={project.slug}
            id={`info-${idx + 1}`}
            className="v-home-projects__info"
          >
            <div
              className={twJoin(
                "v-home-projects__logos text-light",
                `v-home-projects__logos--${project.slug}`
              )}
            >
              {project.info.logos.map((logoSlug) => (
                <CompanyLogo key={logoSlug} slug={logoSlug} />
              ))}
            </div>
            <ul className="v-home-projects__types text-light opacity-75">
              {project.info.types.map((type, idx) => (
                <h6 key={idx} className="h-ttn">
                  {idx !== 0 && <pre>∙</pre>}
                  {type}
                </h6>
              ))}
            </ul>
          </Info>
        ))}
      </div>
    </section>
  );
};

export default HomeProjects;
