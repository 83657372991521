type homeProjectType = {
  slug: string;
  title: string;
  info: { logos: Array<string>; types: Array<string> };
  link: boolean;
  progressBG: string;
  squareSrc: string;
};

export const PROJECTS: Array<homeProjectType> = [
  {
    slug: "hero",
    title: "We Transform Companies",
    info: {
      logos: [],
      types: [],
    },
    link: false,
    progressBG: "#000000",
    squareSrc: "",
  },
  {
    slug: "wirecutter",
    title: "We Ended Choice Paralysis",
    info: {
      logos: ["new-york-times", "wirecutter"],
      types: ["Web", "Strategy"],
    },
    link: true,
    progressBG: "#133091",
    squareSrc: "/images/home/projects/sqs/sq-wire.png",
  },
  {
    slug: "apple",
    title: "We Helped Grow Sales",
    info: {
      logos: ["apple"],
      types: ["Web", "Strategy"],
    },
    link: false,
    progressBG: "#F5F5F1",
    squareSrc: "/images/home/projects/sqs/sq-apple.png",
  },
  {
    slug: "warby-parker",
    title: "We Focus on Winning",
    info: {
      logos: ["warby-parker"],
      types: ["iOS", "Strategy"],
    },
    link: true,
    progressBG: "#45C6F9",
    squareSrc: "/images/home/projects/sqs/sq-warby.png",
  },
  {
    slug: "mgm",
    title: "We Fired Check-in Lines",
    info: {
      logos: ["mgm"],
      types: ["Digital Transformation", "iOS", "Android", "Strategy"],
    },
    link: true,
    progressBG: "#4B2008",
    squareSrc: "/images/home/projects/sqs/sq-mgm.png",
  },
  {
    slug: "riteaid",
    title: "We Prescribed Efficiency",
    info: {
      logos: ["riteaid"],
      types: ["iOS", "Android", "WatchOS", "Strategy"],
    },
    link: true,
    progressBG: "#BC0F31",
    squareSrc: "/images/home/projects/sqs/sq-riteaid.png",
  },
  {
    slug: "crunchbase",
    title: "We Shared Investments",
    info: {
      logos: ["crunchbase"],
      types: ["iOS", "Design", "Strategy"],
    },
    link: true,
    progressBG: "#485083",
    squareSrc: "/images/home/projects/sqs/sq-crunch.png",
  },
  {
    slug: "whitehouse",
    title: "We Digitized Democracy",
    info: {
      logos: ["white-house-alternate"],
      types: ["Strategy", "Web"],
    },
    link: false,
    progressBG: "#af161b",
    squareSrc: "/images/home/projects/sqs/sq-whitehouse.png",
  },
];
