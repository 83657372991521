import React, { useEffect } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { ButtonLink } from "components/Button";

import { PROJECTS } from "data/home";

const HomeMisc = ({ speed }: { speed: number }) => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Transition and offload projects section elements
      // Section II: Miscellaneous (secret, press, investments)
      ScrollTrigger.create({
        trigger: ".v-home-misc",
        invalidateOnRefresh: true,
        markers: false,
        start: "top top",
        end: "top top",
        // Moving to this one
        onEnter: () => {
          // Offload projects
          gsap.set(
            [
              ".v-home-projects__slide",
              ".v-home-projects__background",
              ".v-home-projects__square",
              ".v-home-projects__text",
              ".v-home-projects__details",
              "#info-7",
              "#info-0",
            ],
            { visibility: "hidden" }
          );
          // Reset scrollbar
          gsap.set("#track", { backgroundColor: "#FFFFFF", opacity: 0.5 });
          gsap.set("#bar", { y: "-100%" });
        },
        // Moving to last one
        onEnterBack: () => {
          // Load projects
          gsap.set(
            [
              ".v-home-projects__slide",
              ".v-home-projects__background",
              ".v-home-projects__text",
              ".v-home-projects__details",
              "#sq-7",
              "#info-7",
            ],
            { visibility: "visible" }
          );
          // Reset scrollbar
          gsap.set("#track", { backgroundColor: PROJECTS[6].progressBG, opacity: 1 });
          gsap.set("#bar", { y: 0 });
        },
      });

      // What's our Secret: red square outline fill-in
      gsap.timeline().fromTo(
        "#secret-red",
        { strokeDashoffset: 2000 },
        {
          strokeDashoffset: 0,
          ease: "none",
          scrollTrigger: {
            markers: false,
            scrub: true,
            trigger: "#b-secret",
            start: "top top",
            end: "bottom 50%",
            invalidateOnRefresh: true,
          },
        }
      );

      // What's our secret?
      // prettier-ignore
      // TODO: Figure out why onEnter and onEnterBack are firing twice every time
      ScrollTrigger.create({
      trigger: "#b-secret",
      markers: false,
      start: "center center",
      end: "center center",
      invalidateOnRefresh: true,

      // Moving to this one
      onEnter: () => {
        // Prep press section
        gsap.set("#content-press", { visibility: "visible" });
        gsap.set("#press-bg", { visibility: "visible", opacity: 1 });
        gsap.set("#press-sq", { visibility: "visible", opacity: 1 });
        gsap.set("#press-text", { visibility: "visible" });

      },
      // Moving to last one
      onEnterBack: () => {
        // Offload press section
        gsap.set("#content-press", { visibility: "hidden" });
        gsap.set("#press-bg", { visibility: "hidden", opacity: 0 });
        gsap.set("#press-sq", { visibility: "hidden", opacity: 0 });
        gsap.set("#press-text", { visibility: "hidden" });
      },
    });

      // Press Scene
      // prettier-ignore
      ScrollTrigger.create({
      trigger: "#b-press",
      markers: false,
      start: "top top",
      end: "top top",
      invalidateOnRefresh: true,
      // Moving to this one
      onEnter: () => {
        // Text
        gsap.set("#press-text", { y: 32, opacity: 0, scale: 1.03, visibility: "visible" });
        gsap.to("#press-text", { y: "-=30", duration: speed * 6, delay: speed, ease: "expo.out" });
        gsap.to("#press-text", { scale: 1, opacity: 1, duration: speed * 3, delay: speed, ease: "expo.out" });
        // CTA
        gsap.set("#press-cta", { visibility: "visible", opacity: 0 });
        gsap.to("#press-cta", { opacity: 1, duration: speed * 2, delay: 0.1 });
        // Investments
        gsap.set("#invest", { visibility: "visible" });
      },
      // Moving to last one
      onEnterBack: () => {
        // Text
        gsap.to("#press-text", { y: "+=30", duration: speed * 3, ease: "expo.out" });
        gsap.to("#press-text", { scale: 1.03, opacity: 0, duration: speed * 2, ease: "circ.out" });
        gsap.set("#press-text", { visibility: "hidden", delay: speed });
        // CTA
        gsap.to("#press-cta", { opacity: 0, duration: speed });
        gsap.set("#press-cta", { visibility: "hidden", delay: speed });
        // Investments
        gsap.set("#invest", { visibility: "hidden" });
      },
    });

      // Press scroll
      gsap
        .timeline({
          immediateRender: false,
          scrollTrigger: {
            trigger: `#b-press`,
            scrub: true,
            markers: false,
            start: "top top",
            end: "bottom top",
            invalidateOnRefresh: true,
          },
        })
        // scrollbar
        .fromTo("#bar", { y: "-100%" }, { y: 0, ease: "none" }, 0)
        .fromTo("#press-text span", { y: 15 }, { y: 0, ease: "none" }, 0);

      // Investments
      // prettier-ignore
      ScrollTrigger.create({
      trigger: "#b-invest",
      markers: false,
      start: "top top",
      end: "top top",
      invalidateOnRefresh: true,

      // Moving to this one
      onEnter: () => {
        // Press
        gsap.to('#invest-bg', { opacity: 1, duration: speed });
        gsap.to('#invest-sq', { opacity: 1, duration: speed });
        // Text
        gsap.to('#press-text', { y: '-=32', duration: speed * 3, ease: 'expo.out' });
        gsap.to('#press-text', { scale: 1.03, opacity: 0, duration: speed * 3, ease: 'circ.out' });
        gsap.set('#press-text', { visibility: 'hidden', delay: speed * 3 });
        gsap.set('#invest-text', { y: 32, opacity: 0, scale: 1.03, visibility: 'visible' });
        gsap.to('#invest-text', { y: '-=30', duration: speed * 6, delay: speed, ease: 'expo.out' });
        gsap.to('#invest-text', { scale: 1, opacity: 1, duration: speed * 3, delay: speed, ease: 'expo.out' });
        // CTAs
        gsap.to('#press-cta', { opacity: 0, duration: speed });
        gsap.set('#press-cta', { visibility: 'hidden', delay: speed });
        gsap.set('#invest-cta', { visibility: 'visible', opacity: 0 });
        gsap.to('#invest-cta', { opacity: 1, duration: speed * 2, delay: speed });
      },
      onEnterBack: () => {
        // Press
        gsap.to('#invest-bg', { opacity: 0, duration: speed });
        gsap.to('#invest-sq', { opacity: 0, duration: speed });
        // Text
        gsap.to('#invest-text', { y: '+=30', duration: speed * 3, ease: 'expo.out' });
        gsap.to('#invest-text', { scale: 1.03, opacity: 0, duration: speed * 2, ease: 'circ.out' });
        gsap.set('#invest-text', { visibility: 'hidden', delay: speed });
        gsap.set('#press-text', {
            y: -32,
            opacity: 0,
            scale: 1.03,
            visibility: 'visible',
            delay: speed
        });
        gsap.to('#press-text', { y: '+=30', duration: speed * 6, delay: speed, ease: 'expo.out' });
        gsap.to('#press-text', { scale: 1, opacity: 1, duration: speed * 3, delay: speed, ease: 'expo.out' });
        // CTA
        gsap.to('#invest-cta', { opacity: 0, duration: speed });
        gsap.set('#invest-cta', { visibility: 'hidden', delay: speed });
        gsap.set('#press-cta', { visibility: 'visible', opacity: 0 });
        gsap.to('#press-cta', { opacity: 1, duration: speed * 2, delay: speed });
      }
    })

      // Invest scroll
      gsap
        .timeline({
          immediateRender: false,
          scrollTrigger: {
            trigger: `#b-invest`,
            scrub: true,
            markers: false,
            start: "top top",
            end: "bottom bottom",
            invalidateOnRefresh: true,
          },
        })
        // scrollbar
        .fromTo("#bar", { y: "-100%" }, { y: 0, duration: 10, ease: "none" }, 0)
        .fromTo("#invest-text span", { y: 15 }, { y: 0, duration: 10, ease: "none" }, 0);
    });
    return () => ctx.revert();
  }, [speed]);

  return (
    <section className="v-home-misc bg-black text-light">
      {/* What's our secret */}
      {/* Normal scrolling section that breaks up the slideshows */}
      <div id="b-secret" className="v-home-misc__secret bg-dark">
        {/* SQUARE */}
        {/* Fixed and centered square like the other slides, put clipped by the parent using clip-path */}
        <div className="v-home__slide">
          <svg
            id="secret-sq"
            className="v-home__square square"
            width="500"
            height="500"
            viewBox="0 0 500 500"
          >
            <path
              id="test"
              d="M 0 0 H 500 V 500 H 0 L 0 0 z"
              stroke="#303030"
              strokeWidth="10"
              fill="none"
              fillRule="evenodd"
            />
            <path
              id="secret-red"
              d="M 0 0 H 500 V 500 H 0 L 0 0 z"
              stroke="#AE0000"
              strokeWidth="10"
              fill="none"
              fillRule="evenodd"
            />
          </svg>
        </div>
        {/* 0. WHAT'S OUR SECRET? */}
        <h1 id="text-7" className="v-home__text title">
          What’s Our Secret?
        </h1>
        {/* 1. OUR PROCESS */}
        <div className="step">
          <h1 className="step__number opacity-25">1</h1>
          <div className="step__copy step__wrapper">
            <h1 className="step__heading">Our process</h1>
            <p className="step__body text-balance pb-12 pt-6 text-xl">
              It&#39;s our playbook for client success. It’s the infrastructure that allows our team
              to create the great things that drive results for our clients.
            </p>
          </div>
        </div>
        {/* 2. OUR PEOPLE */}
        <div className="step">
          <h1 className="step__number opacity-25">2</h1>
          <div className="step__wrapper">
            <h1 className="step__heading">Our people</h1>
            <p className="step__body text-balance pb-12 pt-6 text-xl">
              Who come from startups, not agencies. Instead of obsessing over awards, they’ve
              focused on satisfying customers and users. They’re ex-CEOs, ex-CTOs, ex-CMOs—people
              who know how to win in the marketplace.
            </p>
            <ButtonLink
              className="step__cta"
              label="Meet our amazing team"
              href="/team"
              size="medium"
            />
          </div>
        </div>
      </div>
      {/* Press */}
      <div id="b-press" className="v-home__block" />
      <div id="content-press" className="v-home__slide v-home-misc__press">
        <div id="press-bg" className="v-home__background press__bg" />
        <div id="press-sq" className="v-home__square press__sq" />
        <h1 id="press-text" className="v-home__text press__text">
          <span className="block">We get you noticed</span>
        </h1>
      </div>
      {/* Investments */}
      <div id="b-invest" className="v-home__block invest" />
      <div id="invest" className="v-home__slide v-home-misc__invest">
        <div id="invest-bg" className="v-home__background invest__bg" />
        <div id="invest-sq" className="v-home__square invest__sq" />
        <h1 id="invest-text" className="v-home__text invest__text">
          <span className="block">We invest in excellence</span>
        </h1>
      </div>
      {/* CTA buttons on last 2 slides */}
      <div className="v-home__details">
        <ButtonLink
          id="press-cta"
          className="press__cta"
          label="View some press hits"
          href="/press"
          size="medium"
        />
        <ButtonLink
          id="invest-cta"
          className="invest__cta"
          label="View more investments"
          href="/investments"
          size="medium"
        />
      </div>
    </section>
  );
};

export default HomeMisc;
